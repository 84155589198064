import {
  RhIcon,
  RhInput,
  RhInputFormik,
  RhInputGroup,
  RhListContainer,
  RhListItem,
  RhPopover,
  RhPopoverMenu,
  RhPopoverToggle,
} from "@rhythm-ui/react";
import * as React from "react";
import { useState } from "react";

import { countryData } from "../componentData";

type setFormikFieldValueType = (
  field: string,
  value: any,
  shouldValidate?: boolean | undefined
) => void;

const PhoneFormik = ({
  values,
  setFormikFieldValue,
  valueKey,
}: {
  values: any;
  setFormikFieldValue: setFormikFieldValueType;
  valueKey: any;
}) => {
  const [countryList, setCountryList] = useState(countryData);
  // const defaultOption = countryList[100];
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const phoneInputValue = React.useMemo(
    () => values[valueKey],
    [values[valueKey]]
  );

  const onCountrySearch = (search: string) => {
    setCountryList(
      countryData.filter((option) =>
        option.name.toLowerCase().includes(search.toLowerCase())
      )
    );
    setSearch(search);
  };

  const onCountrySelect = (dialCode: string) => {
    setFormikFieldValue(valueKey, {
      ...phoneInputValue,
      dialCode,
    });

    setIsOpen(false);
  };

  const selectedCountryCode = React.useMemo(() => {
    const dialCode =
      phoneInputValue?.dialCode !== undefined
        ? phoneInputValue.dialCode
        : "+91";

    return countryData.find((item) => item.dialCode === dialCode);
  }, [values]);

  return (
    <RhInputGroup className="pl-tnano">
      <RhPopover
        isOpen={isOpen}
        placement="bottom-start"
        offset={[-12, 10]}
        className="bg-white dark:bg-dark-800"
        onToggle={() => {
          setIsOpen(false);
          onCountrySearch("");
        }}
      >
        <RhPopoverToggle
          onClick={() => {
            setIsOpen((state) => !state);
            onCountrySearch("");
          }}
          type="button"
        >
          <div className="flex items-center justify-between flex-nowrap">
            <span className="pr-1">{selectedCountryCode?.flag}</span>
            <span>{selectedCountryCode?.dialCode}</span>
            <RhIcon
              icon="heroicons:chevron-down-solid"
              className="text-xl text-gray-400"
            />
          </div>
        </RhPopoverToggle>
        <RhPopoverMenu className="z-10">
          <RhListContainer className="rounded relative w-[250px] overflow-auto shadow-[0_4px_8px_0px_rgba(0,0,0,0.24)] h-80 bg-white border-1 border-gray-400">
            <div className="sticky top-0 p-tmini bg-white">
              <RhInputGroup className="space-x-2 px-tmini">
                <RhInput
                  value={search}
                  onChange={(e) => onCountrySearch(e.target.value)}
                  className="w-full"
                  placeholder="Search Country"
                  autoFocus
                />
                <RhIcon
                  className="text-hint icon-md ml-tnano"
                  icon="tabler:search"
                />
              </RhInputGroup>
            </div>

            {countryList.map((country, index) => (
              <RhListItem
                key={`country-${index}`}
                onClick={() => onCountrySelect(country.dialCode)}
                className="cursor-pointer"
              >
                <p>{country.flag + " " + country.name}</p>
              </RhListItem>
            ))}
          </RhListContainer>
        </RhPopoverMenu>
      </RhPopover>
      <RhInputFormik
        type={"tel"}
        name={`${valueKey}.phoneNumber`}
        className="w-full pl-tpico"
        data-testid="phone-number"
      />
    </RhInputGroup>
  );
};

export default PhoneFormik;
